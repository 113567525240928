import {
  AudioField,
  VideoField,
  DataType,
  EventsType,
  MediaStatusEnum
} from './types'

export const audioFieldDefault: AudioField = {
  event: 'podcast',
  podcastCurrentTime: 0,
  podcastDuration: 0,
  podcastPercent: 0,
  podcastProvider: 'HTML5 Podcast Player',
  podcastStatus: MediaStatusEnum.EMPTY,
  podcastTitle: '',
  podcastUrl: '',
  podcastVisible: true
}
export const videoFieldDefault: VideoField = {
  event: 'video',
  videoCurrentTime: 0,
  videoDuration: 0,
  videoPercent: 0,
  videoProvider: 'HTML5 Video Player',
  videoStatus: MediaStatusEnum.EMPTY,
  videoTitle: '',
  videoUrl: '',
  videoVisible: true
}

export const pushDataLayer = (event: EventsType, attrs: DataType): void => {

  const pushHandler = {
    podcastAudio: {
      ...attrs.podcastAudio
    },
    videoMedia: {
      ...attrs.videoMedia
    }
  }

  window.dataLayer.push(pushHandler[event])
}
