
import { defineComponent, PropType } from 'vue';

import { ResponsiveImageSet } from '@/types';

import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue'

export default defineComponent({
  components: {
    ResponsiveImage,
  },
  props: {
    image: {
      type: Object as PropType<ResponsiveImageSet>,
      default: null,
    },
  },
});
