
import { defineComponent, ref, onBeforeUnmount } from 'vue';

export default defineComponent({
  props: {
    backgroundImage: {
      type: String,
      required: true,
    },
    logoImage: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    square: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const squareHeight = ref(0);
    const updateWidth = () => {
      squareHeight.value = window.innerWidth;
    };

    if (props.square) {
      updateWidth();
      window.addEventListener('resize', updateWidth);
      onBeforeUnmount(() => window.removeEventListener('resize', updateWidth));
    }

    return {
      squareHeight,
    };
  },
});
