import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["square-carousel-item", {
      'square-carousel-item--no-image': !_ctx.image,
    }])
  }, [
    (_ctx.image)
      ? (_openBlock(), _createBlock(_component_ResponsiveImage, {
          key: 0,
          class: "square-carousel-item__background-image absolute inset-0",
          lightDarkOverlay: "",
          responsiveImageSet: _ctx.image
        }, null, 8, ["responsiveImageSet"]))
      : _createCommentVNode("", true)
  ], 2))
}