
import { defineComponent, PropType } from 'vue';
import useTailwind from '@/use/useTailwind';

import Badge from '@/components/Badge/Badge.vue';
import Button, { BUTTON_THEMES } from '@/components/Button/Button.vue';
import RImageSrcset from '@/components/RImageSrcset/RImageSrcset.vue';

import { ImageWithSources, PointTypesEnum } from '@/types';

const SHOWCASE_CLICK_EVENT = 'showcaseClick';

export default defineComponent({
  components: {
    Badge,
    Button,
    RImageSrcset,
  },
  props: {
    hasCtaButton: {
      type: Boolean,
      default: true,
    },
    displayRight: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: String,
      default: '',
    },
    image: {
      type: Object as PropType<ImageWithSources>,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    points: {
      type: String,
      default: '',
    },
    pointsText: {
      type: String,
      default: 'IQOS Points',
    },
    idRef: {
      type: String,
      default: '',
    },
  },
  emits: [SHOWCASE_CLICK_EVENT],
  setup(_, { emit }) {
    return {
      BUTTON_THEMES,
      PointTypesEnum,
      showcaseClick: () => emit(SHOWCASE_CLICK_EVENT),
      turquoiseColor: useTailwind().theme.colors['primary-turquoise'],
      orangeColor: useTailwind().theme.colors['secondary-amber'],
    };
  },
});
