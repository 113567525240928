
import useTailwind from '@/use/useTailwind';
import { defineComponent } from 'vue';

const screens = useTailwind().theme.screens;

/**
 * Find the first image available from the target(desired size) to the lowest DPI decreasing the DPI level by 1 on every iteration.
 * If searching for Desktop images, and none are found, uses the Mobile ones, following the same algorithm.
 * @param {Object} cmsImageObj - required image object that has 6 DPI options(for mobile and desktop, in x1, x2 and x3 DPI for each) in a structure containing at least those properties(even if they are null): {srcMobileX1: 'URL', srcMobileX2: 'URL', srcMobileX3: 'URL', srcDesktopX1: 'URL', srcDesktopX2: 'URL', srcDesktopX3: 'URL'}
 * @param {Object} options
 * @param {String} options.target - the desired image size. e.g. 'srcMobileX1'
 * @param {String} options.originalTarget - to be able to identify which was the initial target
 * @param {Object} options.memoTarget - memoization object to hold where to look for images in case it found before
 * */
const findAnImage = (
  cmsImageObj,
  { target = undefined, originalTarget = undefined, memoTarget = {} } = {}
) => {
  const MINIMUM_DESKTOP_DPI = 'srcDesktopX1';
  const MAXIMUM_MOBILE_DPI = 'srcMobileX3';

  if (!cmsImageObj) {
    throw new Error('Object is missing');
  }
  if (cmsImageObj && typeof cmsImageObj !== 'object') {
    throw new Error('cmsImageObj must be an object');
  }
  // returns memoized target if present
  if (memoTarget[target]) return memoTarget[target];

  const desiredTarget = cmsImageObj[target];
  if (desiredTarget) {
    memoTarget[originalTarget] = desiredTarget;
    return desiredTarget;
  }
  // if no desktop image found, search for one available on mobile sizes
  if (target === MINIMUM_DESKTOP_DPI) {
    return findAnImage(cmsImageObj, {
      target: MAXIMUM_MOBILE_DPI,
      originalTarget: target,
      memoTarget,
    });
  }
  // base to stop recursive case;
  // No image is available when a 0(Zero) is present on the target,
  // only happening when reaching srcMobileX1 and trying to call findAnImage one more time
  if (target.indexOf('0') !== -1) return null;
  // gets the level based on the last number(3, 2 or 1)
  const previousTargetLevel = Number(target[target.length - 1]);
  // newTarget it's always decreasing 1 from the previousTargetLevel
  const newTarget = `${target.slice(0, -1)}${previousTargetLevel - 1}`;
  return findAnImage(cmsImageObj, {
    target: newTarget,
    originalTarget: target,
    memoTarget,
  });
};

export default defineComponent({
  name: 'RImageSrcset',
  props: {
    alt: {
      required: true,
      type: String,
    },
    srcMobileX1: {
      default: null,
      type: String,
    },
    srcMobileX2: {
      default: null,
      type: String,
    },
    srcMobileX3: {
      default: null,
      type: String,
    },
    srcDesktopX1: {
      default: null,
      type: String,
    },
    srcDesktopX2: {
      default: null,
      type: String,
    },
    srcDesktopX3: {
      default: null,
      type: String,
    },
    imageClasses: {
      default: '',
      type: String,
    },
    darken: {
      type: Boolean,
      default: false,
    },
    lightDarkOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      memoizedBestFitImageMapping: {},
      mobileSrcset: null,
      desktopSrcset: null,
      screens,
    };
  },
  mounted() {
    this.mobileSrcset = `
        ${this.findBestFitImage('srcMobileX2')} 2x,
        ${this.findBestFitImage('srcMobileX3')} 3x,
        ${this.findBestFitImage('srcMobileX1')}
      `;
    this.desktopSrcset = `
        ${this.findBestFitImage('srcDesktopX2')} 2x,
        ${this.findBestFitImage('srcDesktopX3')} 3x,
        ${this.findBestFitImage('srcDesktopX1')}
      `;
  },
  methods: {
    findBestFitImage(target) {
      return findAnImage(this, {
        target,
        originalTarget: target,
        memoTarget: this.memoizedBestFitImageMapping,
      });
    },
  },
});
