
import { defineComponent, PropType, computed } from 'vue';

import SquareCarouselItem from './components/SquareCarouselItem/SquareCarouselItem.vue';

import useCarousel from '@/use/useCarousel';

import Carousel from '@/components/Carousel/Carousel.vue';

import { VueperSlide } from 'vueperslides';
import { ImageWithSources } from '@/types';

const visibleSlidesPerBreakpoint = {
  xl: 3.05,
  lg: 3.05,
  md: 2.1,
  sm: 1.05,
};

export default defineComponent({
  components: {
    VueperSlide,
    Carousel,
    SquareCarouselItem,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    images: {
      type: Array as PropType<ImageWithSources[]>,
      required: true,
    },
    hasArchiveTile: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const totalItems = computed(
      () => props.images.length + (props.hasArchiveTile ? 1 : 0)
    );

    const {
      hideLeftArrow,
      hideRightArrow,
      changeSlide,
      carouselBreakpointsConfig,
      visibleSlides,
      numberOfFillerSlides,
    } = useCarousel({
      visibleSlidesPerBreakpoint,
      totalItems,
    });

    return {
      carouselBreakpointsConfig,
      changeSlide,
      hideLeftArrow,
      hideRightArrow,
      visibleSlides,
      numberOfFillerSlides,
      totalItems,
    };
  },
});
