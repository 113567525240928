export interface ResponsiveImageSet {
  alt?: string;
  mobile: {
    x1: string; // url of image @1x
    x2?: string; // url of image @2x
    x3?: string; // url of image @3x
  };
  desktop: {
    x1: string; // url of image @1x
    x2?: string; // url of image @2x
    x3?: string; // url of image @3x
  };
}

export interface ResponsiveImageDTO {
  image_desktop_1x?: { url: string };
  image_desktop_2x?: { url: string };
  image_desktop_3x?: { url: string };
  image_mobile_1x?: { url: string };
  image_mobile_2x?: { url: string };
  image_mobile_3x?: { url: string };
}

// Alias
export type ImageWithSources = ResponsiveImageSet;

export type Video = {
  videoUrl: string;
  name: string;
  leadfamlyUrl?: string;
  trackingAction: string;
  uid: string;
  image: ImageWithSources | ResponsiveImageSet;
  progress: { [key: string]: boolean };
};

export type Podcast = {
  audioUrl: string;
  name: string;
  leadfamlyUrl?: string;
  trackingAction: string;
  uid: string;
  image: ImageWithSources | ResponsiveImageSet;
  progress: { [key: string]: boolean };
};

export enum PointTypesEnum {
  IQOS_POINT = 'IQOS Points',
  STATUS_PUNKTE = 'Statuspunkte',
}

declare global {
  interface Window {
    wyng: unknown;
  }

  interface Navigator extends Navigator {
    canShare: (options?: ShareData) => boolean;
  }
}
