type Seconds = number;
export enum MediaStatusEnum {
  PLAY = 'play',
  PAUSE = 'pause',
  SKIP = 'skip',
  COMPLETED = 'completed',
  RESUME_AFTER_SKIP = 'resumeafterskip',
  PLAYING = 'playing',
  EMPTY = '',
}
export interface ProductFieldObject {
  name: string; // The product ID or SKU
  id: string | number; // name of the product
  price?: number; // Price is the points (for Iqos club only)
  brand?: string; // The brand associated with the product
  category?: string; // Main category
  variant?: string; // variant of the product color_name
  quantity?: number; // The quantity of a product
  availability?: string;
}
export interface ImpressionFieldObject extends ProductFieldObject {
  list?: string; // Dependant on the case, either 'Product List' or 'Carousel'
  position?: number; // The product's position in a list or collection
}
export interface ProductClickObject extends ProductFieldObject {
  position?: number; // The product's position in a list or collection
}
export interface ActionField {
  id: string | number; // Transaction ID. Required for purchases and refunds.
  affiliation?: string;
  revenue?: string | number; // Total transaction value (in points)s
}
export interface AudioField {
  event: string;
  podcastCurrentTime: Seconds; // This is an integer representing the time of an event in the podcast in seconds.
  podcastDuration: Seconds; // This is an integer representing the total duration of the podcast in seconds.
  podcastPercent: number; // This is an integer (0-100) that corresponds to the percentage of the played podcast where an event occurred.
  podcastProvider: string; // It corresponds to the name of the podcast player
  podcastStatus: MediaStatusEnum; // It corresponds to the podcast status where an event was detected ("play", "playing" "pause", "skip", completed)
  podcastTitle: string; // The filename of the played podcast
  podcastUrl: string; // The podcast URL of the podcat
  podcastVisible: boolean; // The value is "true" if the podcast is visible in the display area, and "false" if it is not, for example because it is "below the fold" (visible with scrolling) or is on a tab in the background.
}
export interface VideoField {
  event: string;
  videoCurrentTime: number; // This is an integer representing the time of an event in the media in seconds.
  videoDuration: number; // This is an integer representing the total duration of the media in seconds.
  videoPercent: number; // This is an integer (0-100) that corresponds to the percentage of the played media where an event occurred.
  videoProvider: string; // It corresponds to the name of the media player
  videoStatus: MediaStatusEnum; // It corresponds to the media status where an event was detected ("play", "playing" "pause", "skip", completed)
  videoTitle: string; // The filename of the played media
  videoUrl: string; // The media URL
  videoVisible: boolean; // The value is "true" if the media is visible in the display area, and "false" if it is not, for example because it is "below the fold" (visible with scrolling) or is on a tab in the background.
}
export interface StoryField {
  event: string;
  story_title: string;
  story_category: string;
}
export interface StoryInteractionField {
  event: string,
  storyTitle: string, // The title of the story
  clickText: string, // The text of the button or link in the original language
  clickURL: string, // URL of the destination referred to by the button or link.
  }
export interface DataType {
  productImpressionList?: ImpressionFieldObject[];
  productList?: ProductFieldObject[] | ProductClickObject[];
  list?: string;
  actionFieldPurchase?: ActionField;
  podcastAudio?: AudioField;
  videoMedia?: VideoField;
  story?: StoryField;
  storyInteractions?: StoryInteractionField;
}
interface Events {
  podcastAudio?: string;
  videoMedia?: string;
  productPurchase?: string;
  productImpression?: string;
  productClick?: string;
  productDetail?: string;
  productAddCart?: string;
  productCheckout?: string;
  storyView?: string;
  clickEvent?: string;
}
export type EventsType = keyof Events;
