import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.activeTrack)
    ? (_openBlock(), _createElementBlock("audio", {
        key: 0,
        id: _ctx.idRef,
        ref: "refPodcastplayer",
        class: "audio-player",
        controls: "",
        src: _ctx.podcast.audioUrl,
        onTimeupdate: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onProgressAudio && _ctx.onProgressAudio(...args))),
        onPlay: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onPlay && _ctx.onPlay(...args))),
        onPause: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onPause && _ctx.onPause(...args))),
        onEnded: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onAudioEnds && _ctx.onAudioEnds(...args))),
        onSeeked: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onSeeked && _ctx.onSeeked(...args))),
        onSeeking: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onSeeking && _ctx.onSeeking(...args))),
        onCanplay: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onCanPlay && _ctx.onCanPlay(...args)))
      }, " Your browser does not support the audio element. ", 40, _hoisted_1))
    : _createCommentVNode("", true)
}