
import { defineComponent, ref, Ref, reactive } from 'vue';
import { useGtag } from 'vue-gtag-next';
import { useStore } from 'vuex';

import { videoFieldDefault, pushDataLayer } from '@/utils/GTMCustomDataLayers/media'
import { VideoField, MediaStatusEnum } from '@/utils/GTMCustomDataLayers/types'

import Button from '@/components/Button/Button.vue';
import ContentIntro from '@/components/ContentIntro/ContentIntro.vue';
import CarouselSection from '@/components/CarouselSection/CarouselSection.vue';
import Hero from '@/components/Hero/Hero.vue';
import Icon, { ICON_THEMES } from '@/components/Icon/Icon.vue';
import Modal from '@/components/Modal/Modal.vue';
import Showcase from '@/components/Showcase/Showcase.vue';
import AudioPodcast from '@/components/AudioPodcast/AudioPodcast.vue';

import { VideoTeaser } from '../types';
import { ImageWithSources, Podcast } from '@/types';

const PERCENTAGE_TO_AWARD_POINTS = 95;

export default defineComponent({
  components: {
    AudioPodcast,
    Button,
    CarouselSection,
    ContentIntro,
    Hero,
    Icon,
    Modal,
    Showcase,
  },
  setup() {
    const store = useStore();
    const { event } = useGtag();

    // hero
    const heroImage = store.state.mtnFinaleModule.heroImageUrl;

    // video
    const state = reactive({
      currentVideoStatus: 'pause',
      lastPlayedTime: 0,
      sentTrackingPercentage: 0
    });
    const pointsGivenForVideo: Ref<boolean> = ref(false);
    const selectedVideo: Ref<VideoTeaser | null> = ref(null);
    const video: VideoTeaser = store.state.mtnFinaleModule.videos[0];
    const videoPlayer: Ref<HTMLVideoElement | null> = ref(null);
    const videoPercentageTracked: Ref<Record<string, boolean>> = ref({});

    const shouldShowSuccessVideoMessage: Ref<boolean> = ref(false);

    const displayVideoModal = (videoTeaser: VideoTeaser) => {
      selectedVideo.value = videoTeaser;
      event('Mtn video button clicked', {
        event_category: `video-${videoTeaser.title}`,
        event_label: 'start-watching',
      });
    };

    const prepareAndSendGTMObject = (newStatus, currentTime, duration) => {
      const podcastGTMObject: VideoField = {
        ...videoFieldDefault,
        videoCurrentTime: currentTime,
        videoPercent: getPlayedTimePercentage(duration, currentTime),
        videoStatus: newStatus,
      }
      pushDataLayer('videoMedia', { videoMedia: podcastGTMObject })
    }

    const onCanPlay = () => {
      const { duration } = videoPlayer.value;

      videoFieldDefault.videoDuration = duration
      videoFieldDefault.videoTitle = video.title
      videoFieldDefault.videoUrl = video.url
    }

    const onPlay = () => {
      if (!videoPlayer.value) return;
      const { duration, currentTime, seeking } = videoPlayer.value;

      const videoStatusHandler = {
        pause: () => {
          // if media is resumed after pause
          if (!seeking) {
            prepareAndSendGTMObject(MediaStatusEnum.PLAY, currentTime, duration)
            state.currentVideoStatus = MediaStatusEnum.PLAY
          }
        },
        play: () => {
          // if media is directly skipped
          prepareAndSendGTMObject(MediaStatusEnum.SKIP, state.lastPlayedTime, duration)
          prepareAndSendGTMObject(MediaStatusEnum.RESUME_AFTER_SKIP, currentTime, duration)

          state.currentVideoStatus = MediaStatusEnum.PLAY
          state.lastPlayedTime = currentTime
        },
        skip: () => {
          // if media is resumed after skipped
          prepareAndSendGTMObject(MediaStatusEnum.SKIP, state.lastPlayedTime, duration)
          prepareAndSendGTMObject(MediaStatusEnum.RESUME_AFTER_SKIP, currentTime, duration)

          state.currentVideoStatus = MediaStatusEnum.PLAY
          state.lastPlayedTime = currentTime
        },
        default: () => void 0
      }
      const pushMediaGTMObject = videoStatusHandler[state.currentVideoStatus]
        || videoStatusHandler['default']
      pushMediaGTMObject()
    }
    const onPause = () => {
      const { duration, currentTime, seeking } = videoPlayer.value;
      if (!seeking) {
        prepareAndSendGTMObject(MediaStatusEnum.PAUSE, currentTime, duration)
        state.currentVideoStatus = MediaStatusEnum.PAUSE
      }
    }

    const onSeeked = () => {
      state.currentVideoStatus = MediaStatusEnum.SKIP
    }

    // When the video plays and the progress is above 95%,
    // we track the event in GA and notify the backend
    const onProgressVideo = () => {
      if (!videoPlayer.value) return;

      const { duration, currentTime } = videoPlayer.value;
      const percentage = (currentTime * 100) / duration;
      const flooredPercentage = Math.floor(percentage);

      // track video % while playing
      if (flooredPercentage % 10 === 0 &&
        state.sentTrackingPercentage !== flooredPercentage
      ) {
        prepareAndSendGTMObject(MediaStatusEnum.PLAYING, currentTime, duration)
        state.sentTrackingPercentage = flooredPercentage
      }

      // track video watched in %
      if (
        flooredPercentage % 10 === 0 &&
        !videoPercentageTracked.value[String(flooredPercentage)]
      ) {
        // track and prevent events duplication
        videoPercentageTracked.value[String(flooredPercentage)] = true;
        event('Mtn video percentage', {
          event_category: `video-${video.title}`,
          event_label: `watched up to ${flooredPercentage}%`,
        });
      }

      // track when awarding points
      if (
        percentage > PERCENTAGE_TO_AWARD_POINTS &&
        !pointsGivenForVideo.value
      ) {
        pointsGivenForVideo.value = true;
        event('Mtn video watched', {
          event_category: `video-${video.title}`,
          event_label: 'finish-watching',
        });
        store.dispatch('mtnFinaleModule/consumedContent', video.trackingAction);
      }

      // Show success message aftr video successfully completed
      shouldShowSuccessVideoMessage.value = pointsGivenForVideo.value && percentage >= 100;
    };

    const onEnded = () =>
      prepareAndSendGTMObject(
        MediaStatusEnum.COMPLETED,
        videoFieldDefault.videoDuration,
        videoFieldDefault.videoDuration
      )

    const handleModalClose = () => {
      selectedVideo.value = null;
      shouldShowSuccessVideoMessage.value = false;
    };

    const getPlayedTimePercentage = (duration, currentTime) => {
      const timePercentage = (currentTime / duration) * 100
      return Number(timePercentage.toFixed(2))
    }

    // podcast
    const selectedPodcast: Ref<Podcast | null> = ref(null);
    const podcast: Podcast = store.state.mtnFinaleModule.podcasts[0];

    const onPlayPodcast = () => {
      selectedPodcast.value = podcast;
    };
    const onPodcastFinished = () => {
      store.dispatch('mtnFinaleModule/consumedContent', podcast.trackingAction);
    };

    const stopPodcast = () => {
      selectedPodcast.value = null;
    }

    // carousel
    const carouselImages: ImageWithSources[] =
      store.state.mtnFinaleModule.carouselImages;

    return {
      ICON_THEMES,
      carouselImages,
      displayVideoModal,
      handleModalClose,
      heroImage,
      onPlayPodcast,
      onPodcastFinished,
      onProgressVideo,
      podcast,
      selectedPodcast,
      selectedVideo,
      shouldShowSuccessVideoMessage,
      stopPodcast,
      video,
      videoPlayer,
      onSeeked,
      onPlay,
      onPause,
      onCanPlay,
      onEnded
    };
  },
});
