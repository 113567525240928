import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "r-image-srcset" }
const _hoisted_2 = ["media", "srcset"]
const _hoisted_3 = ["src", "srcset", "alt"]
const _hoisted_4 = {
  key: 0,
  class: "absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"
}
const _hoisted_5 = {
  key: 1,
  class: "absolute top-0 left-0 w-full h-full bg-black bg-opacity-20"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("picture", _hoisted_1, [
    _createElementVNode("source", {
      media: `(min-width:${_ctx.screens.md})`,
      srcset: _ctx.desktopSrcset
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      class: _normalizeClass(_ctx.imageClasses),
      src: _ctx.srcMobileX1,
      srcset: _ctx.mobileSrcset,
      alt: _ctx.alt
    }, null, 10, _hoisted_3),
    (_ctx.darken)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.lightDarkOverlay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}