
import {
  PropType,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  computed,
} from 'vue';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

import { useGtag } from 'vue-gtag-next';

import {
  audioFieldDefault,
  pushDataLayer,
} from '@/utils/GTMCustomDataLayers/media';
import { AudioField, MediaStatusEnum } from '@/utils/GTMCustomDataLayers/types';
import { Podcast } from '@/types';

const PERCENTAGE_CONSIDERED_AS_SKIP = 5;
const AMOUNT_OF_CHECKPOINTS_TO_AWARD_POINTS = 9; // out of 11
const EVENTS = {
  PODCAST_FINISHED: 'podcastFinished',
  AUDIO_ENDS: 'audioEnds',
  AUDIO_FRAGMENT_LISTENED: 'audioFragmentListened',
};

export default defineComponent({
  components: {},
  props: {
    podcast: Object as PropType<Podcast>,
    idRef: {
      type: String,
      required: true,
    },
  },
  emits: [
    EVENTS.PODCAST_FINISHED,
    EVENTS.AUDIO_ENDS,
    EVENTS.AUDIO_FRAGMENT_LISTENED,
  ],
  setup(props, context) {
    const state = reactive({
      activeTrack: props.podcast.audioUrl,
      pointsSentForActiveTrack: false,
      lastPercentage: 0,
      currentMediaStatus: '',
      lastPlayedTime: 0,
      sentTrackingPercentage: 0,
    });
    const { event } = useGtag();
    const isCustomSeeking = ref(false);

    // reference to DOM element
    const refPodcastplayer = ref(null);
    const podcastTrackingName = computed(
      () =>
        `Podcast ${props.podcast.name} | Phillip Morris Germany`
    );

    const prepareAndSendGTMObject = (newStatus, currentTime, duration) => {
      const podcastGTMObject: AudioField = {
        ...audioFieldDefault,
        podcastCurrentTime: currentTime,
        podcastPercent: getPlayedTimePercentage(duration, currentTime),
        podcastStatus: newStatus,
      };
      pushDataLayer('podcastAudio', { podcastAudio: podcastGTMObject });
    };

    const onCanPlay = () => {
      const { duration, currentTime } = refPodcastplayer.value;

      if (!state.currentMediaStatus) {
        audioFieldDefault.podcastDuration = duration;
        audioFieldDefault.podcastTitle = podcastTrackingName.value;

        prepareAndSendGTMObject(MediaStatusEnum.PLAY, currentTime, duration);

        state.currentMediaStatus = MediaStatusEnum.PLAY;
      }
    };

    const onPlay = () => {
      if (!refPodcastplayer.value) return;
      const { duration, currentTime, seeking } = refPodcastplayer.value;
      const videoStatusHandler = {
        pause: () => {
          // if podcast is resumed after pause
          if (!seeking) {
            prepareAndSendGTMObject(
              MediaStatusEnum.PLAY,
              currentTime,
              duration
            );
            state.currentMediaStatus = MediaStatusEnum.PLAY;
          }
        },
        play: () => {
          // if podcast is directly skipped
          prepareAndSendGTMObject(
            MediaStatusEnum.SKIP,
            state.lastPlayedTime,
            duration
          );
          prepareAndSendGTMObject(
            MediaStatusEnum.RESUME_AFTER_SKIP,
            currentTime,
            duration
          );

          state.currentMediaStatus = MediaStatusEnum.PLAY;
          state.lastPlayedTime = currentTime;
        },
        skip: () => {
          // if podcast is resumed after skipped
          prepareAndSendGTMObject(
            MediaStatusEnum.SKIP,
            state.lastPlayedTime,
            duration
          );
          prepareAndSendGTMObject(
            MediaStatusEnum.RESUME_AFTER_SKIP,
            currentTime,
            duration
          );

          state.currentMediaStatus = MediaStatusEnum.PLAY;
          state.lastPlayedTime = currentTime;
        },
        default: () => void 0,
      };
      const pushPodcastGTMObject =
        videoStatusHandler[state.currentMediaStatus] ||
        videoStatusHandler['default'];
      pushPodcastGTMObject();
    };

    const onPause = () => {
      const { duration, currentTime, seeking } = refPodcastplayer.value;
      if (!seeking) {
        state.currentMediaStatus = MediaStatusEnum.PAUSE;
        state.lastPlayedTime = currentTime;
        prepareAndSendGTMObject(MediaStatusEnum.PAUSE, currentTime, duration);
      }
    };

    const onSeeking = throttle(() => {
      isCustomSeeking.value = true;
    }, 1000);

    const onSeeked = debounce(() => {
      isCustomSeeking.value = false;
      state.currentMediaStatus = MediaStatusEnum.SKIP;
    }, 3000);

    const onProgressAudio = () => {
      if (!refPodcastplayer.value) return;
      const { duration, currentTime, volume, muted, paused, seeking } =
        refPodcastplayer.value;

      const percentage = (currentTime * 100) / duration;

      const flooredPercentage = Math.floor(percentage);
      const hasSound = volume > 0 && !muted;

      // track video % while playing
      if (
        flooredPercentage % 10 === 0 &&
        state.sentTrackingPercentage !== flooredPercentage
      ) {
        prepareAndSendGTMObject(MediaStatusEnum.PLAYING, currentTime, duration);
        state.sentTrackingPercentage = flooredPercentage;
      }

      if (
        !isCustomSeeking.value &&
        flooredPercentage > 0 &&
        flooredPercentage % 10 === 0 &&
        !props.podcast.progress[String(flooredPercentage)] &&
        hasSound
      ) {
        context.emit(EVENTS.AUDIO_FRAGMENT_LISTENED, {
          progress: flooredPercentage,
          trackAction: props.podcast.trackingAction,
        });
        // We need to send only once every 10% the GA tracking event
        // More info in ticket: https://jira.avantgarde-digital.de/browse/PMIDCE-551
        event('Podcast listen percentage', {
          event_category: podcastTrackingName.value,
          event_label: `listened up to ${flooredPercentage}%`,
        });
      }

      // Award points when percentage above margin
      if (
        Object.values(props.podcast.progress).reduce(
          (acc, item) => acc + (item ? 1 : 0),
          0
        ) >= AMOUNT_OF_CHECKPOINTS_TO_AWARD_POINTS &&
        !state.pointsSentForActiveTrack &&
        hasSound
      ) {
        state.pointsSentForActiveTrack = true;
        context.emit(EVENTS.PODCAST_FINISHED, props.podcast);
      }

      const skippedAmountPercentage = Math.abs(
        percentage - state.lastPercentage
      );
      // Track when user skipped above margin
      if (skippedAmountPercentage >= PERCENTAGE_CONSIDERED_AS_SKIP) {
        event('Podcast skip', {
          event_category: podcastTrackingName.value,
          event_label: `skipped ${skippedAmountPercentage}%`,
        });
      }
      state.lastPercentage = percentage;
      if (!paused && !seeking) {
        state.lastPlayedTime = currentTime;
      }
    };

    const getPlayedTimePercentage = (duration, currentTime) => {
      const timePercentage = Math.floor((currentTime / duration) * 100);
      return timePercentage;
    };

    // Autoplay when podcast prop changes
    const stopWatchingPodcastProp = watch(
      () => props.podcast,
      async () => {
        // Wait until the refPodcastplayer has updated to begin playing again
        await refPodcastplayer.value.load();
        refPodcastplayer.value.play();
        state.pointsSentForActiveTrack = false;
        // Restore the % tracked
        // state.percentageTracked = { ...generatePercentageTrackedMap() };

        state.currentMediaStatus = '';
      }
    );

    const onAudioEnds = () => {
      prepareAndSendGTMObject(
        MediaStatusEnum.COMPLETED,
        audioFieldDefault.podcastDuration,
        audioFieldDefault.podcastDuration
      );
      context.emit(EVENTS.AUDIO_ENDS, { podcast: props.podcast, pointsSent: state.pointsSentForActiveTrack });
    };

    onMounted(() => refPodcastplayer.value.play());

    onBeforeUnmount(() => {
      state.activeTrack = '';
      stopWatchingPodcastProp();
    });

    return {
      ...toRefs(state),
      refPodcastplayer,
      onProgressAudio,
      onAudioEnds,
      onSeeked,
      onSeeking,
      onPlay,
      onPause,
      onCanPlay,
    };
  },
});
